import Axios from 'axios'
import {isbuild} from './isbuild'
let errorUrl = 'https://i.adyounger.com'
Axios.defaults.withCredentials=false
const errorSave = new Axios.create({
    baseURL:errorUrl,
})
const errorReq = (arg)=>{
    return errorSave.post('/Requestmini/ygLog',arg)
}
export default errorReq