<template>
   <el-col :span="5">
        <el-date-picker
            v-model="dateValue"
            type="daterange"
            range-separator="至"
            start-placeholder="1"
            end-placeholder="2"
            value-format="yyyy-MM-dd"
            @change="handleChangeDate"
            :picker-options="pickerOptions">
        </el-date-picker>
    </el-col>
</template>

<script>
    export default {
        props:{
            
        },
        data() {
            return {
                pickerOptions: {
                disabledDate: (time) => {
                    return time.getTime() > Date.now()
                },
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
                },
                dateValue: '',
            }
        },
        methods: {
            handleChangeDate() {
                this.$emit('passDate',this.dateValue.slice())
            }
        },
        created(){
            let checkedDate = sessionStorage.getItem('searchDate')
            if(checkedDate){
                let {dtBegin,dtEnd} = JSON.parse(checkedDate)
                this.dateValue = [dtBegin,dtEnd]
            }else{
                const end = new Date();
                var year = end.getFullYear()+'';
                var month = end.getMonth()+1+'';
                var date = end.getDate()+'';
                let endDate= [year,month,date].join('-');
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                var year = start.getFullYear()+'';
                var month = start.getMonth()+1+'';
                var date = start.getDate()+'';
                let startDate= [year,month,date].join('-');
                this.dateValue = [startDate,endDate]
            }
            this.handleChangeDate()
        }
    }
</script>

<style lang="less" scoped>

</style>