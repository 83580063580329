import {BASE_URL} from './base__url'
import Axios from 'axios'
import Vue from 'vue'
import router from '../router'
Axios.defaults.withCredentials=true
const instance = new Axios.create({
    baseURL:BASE_URL,
})
instance.interceptors.request.use(function (config) {
    // 在发送请求之前操作
    let userInfo = sessionStorage.getItem('userInfo')
    let x_token = userInfo ? JSON.parse(userInfo).token : ''
    if(x_token){config.headers.x_token = x_token}
    return config;
  }, function (error) {
    // 对请求错误操作
    return Promise.reject(error);
  });
  var helpReponseMsgNum = 1
  instance.interceptors.response.use(function (response) {
    // 对响应数据操作
    let {status, logout,message} = response.data
    if(status === 1 && logout === 1){
        if(helpReponseMsgNum === 1){
          router.push('/login')
          Vue.prototype.$message({
            showClose: true,
            message: message+'请重新登录。',
            type: 'warning'
          })
          helpReponseMsgNum++
          setTimeout(()=>{
            helpReponseMsgNum = 1
          },500)
        }
    }
    return response;
  }, function (error) {
    // 对响应错误操作
    return Promise.reject(error);
  });
export default instance