import Login from '../views/Login.vue'
import menuRoutes from './menuRoutes'
import Notfind from '../views/NotFind.vue'
let routes = [
    {
      path:'/login',
      name:'Login',
      component:Login,
      meta:{
        title:'登录'
      }
    },
    {
      path:'/admin',
      alias: '/',
      component:()=>import('../layout'),
      children:[
        {
          path: '/',
          redirect:'/admin/home' 
        },
      ]
    },
    {
      path:'*',
      component:Notfind
    }
  ]

  menuRoutes.forEach(item=>{
      if(item.path){
          routes[1].children.push(item)
      }else{
          item.childRoutes.forEach(subitem=>{
            routes[1].children.push(subitem)
          })
      }
  })

  export default routes
  