import { Loading } from 'element-ui'
import {homeData} from '../api/staticPages'
import {smlineOption,lineOption} from "../utils/options"
export default {
    namespaced:true,
    state:()=>({
        homeDataObj:null,
    }),
    getters:{
        newSmlineOption(state){
            let {thousands} = state.homeDataObj
            smlineOption.series[0].data = JSON.parse(thousands.echarts)
            smlineOption.xAxis[0].data = state.homeDataObj.echarts.date
            return smlineOption
        },
        newLineOption(state){
            let {echarts} = state.homeDataObj
            let newLine = {...lineOption}
            newLine.xAxis[0].data = echarts.date
            newLine.series[0].data = echarts.show
            newLine.series[1].data = echarts.money
            return newLine
        }
    },
    mutations:{
        homeDataMut(state,resData){
            state.homeDataObj = resData
        }
    },
    actions:{
        homeDataAct(context){
            let loadingInstance = Loading.service({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(165, 165, 220, .1)'
            })
            homeData().then(res=>{
                context.commit('homeDataMut',res.data.data)
                loadingInstance.close()
            })
        }
    }
}