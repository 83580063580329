import { userLogin } from "../api/login"
import errorReq from "../utils/saveError"
import router from '../router'
import Vue from 'vue'
import { Loading } from 'element-ui'
export default {
    namespaced:true,
    state:()=>({
        userInfo:null
    }),
    getters:{
        username(state){
            return state.userInfo.nickname
        },
        useremail(state){
            return state.userInfo.email
        }
    },
    mutations:{
        saveUserInfoMut(state,userInfo){
            state.userInfo = userInfo
        }
    },
    actions:{
        userLogin(context,userInfo){
            let loadingInstance = Loading.service({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(165, 165, 220, .1)'
            })
            userLogin(userInfo).then(res=>{
                // console.log(res)
                let {data} = res
                if(data.status === 0){//返回的成功状态码
                    let userInfo = {
                        nickname:data.data.nickname,
                        email:data.data.email,
                        token:data.x_token
                    }
                    sessionStorage.setItem('userInfo',JSON.stringify(userInfo))
                    context.commit('saveUserInfoMut',userInfo)
                    router.push('/admin/home')
                    loadingInstance.close()
                }else{
                    Vue.prototype.$message({
                        showClose: true,
                        message: data.message,
                        type: 'warning'
                    })
                    loadingInstance.close()
                }
            }).catch(error=>{
                let project = '海外渠道数据平台'
                let place = '用户登录'
                let savetime = new Date().toLocaleString()
                errorReq({project,place,savetime,error}).then(res=>{
                    console.log('错误信息已保存！')
                })
                loadingInstance.close()
                Vue.prototype.$message({
                    showClose: true,
                    message: '请求异常！',
                    type: 'error'
                })
                
            })
        }
    }
}