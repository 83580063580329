<template>
    <div class="parent">
        {{title}}
        <div 
            @mouseover="isShow=true" 
            @mouseleave="isShow=false"
            class="makeClear"
        >
            <img src="../assets/imgs/wenhao.png" alt="">    
            <transition
                enter-active-class="animate__animated animate__fadeInUp animate__faster" 
                leave-active-class="animate__animated animate__fadeOut"
            >
                <div 
                    class="showDetail" 
                    v-show="isUse" 
                    v-if="isShow" 
                    >
                    <slot>解释说明内容</slot>
                </div>
            </transition>
        </div>
        
    </div>
</template>

<script>
    import 'animate.css'
    export default {
        props:{
            title:{
                type:String,
                default:'请写入标题title'
            },
            isUse:{
                type:Boolean,
                default:true,
            }
        },
        data() {
            return {
                isShow: false,
            }
        },
    }
</script>

<style lang="less" scoped>
    .parent{
        font-size: 16px;
        font-weight: 900;
        background: white !important;
        .makeClear{
            display: inline-block;
            position: relative;
            box-sizing: border-box;
            padding-right:30px;
            >img{
                cursor: help;
                margin-top: 3px;
            }
            .showDetail{
                min-width:200px;
                min-height:60px;
                position: absolute;
                background: white;
                padding: 20px;
                box-sizing: border-box;
                top: -28px;
                left: 32px;
                border:1px solid #ddd;
                border-radius: 3px;
                box-shadow:3px 2px 10px #bdc7ca;
                z-index: 1000;
            }
        }
        
    }
</style>