<template>
    <div 
        ref="diyRef" 
        class="diySelect" 
        :id="selectId"
        @click="isOpen=true" 
        @mouseover="handleHover" 
        @mouseleave="handleNone"
        >
        <span class="colorBlock" :style="{'background':mackColor}"></span>
        <div class="showDataItem">{{showValue}}</div>
        <i :class="isOpen ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></i>
        <div 
            class="diyOptions" 
            :id="optionId"
            v-show="isOpen&&lineShowList.length>1"
            @mouseover.stop="handleNone" 
            @mouseleave.stop>
            <span 
                v-for="item in lineShowList" 
                :key="item"
                :class="{active:item == showValue}"
                @click.stop="handleCheckOption(item)"
            >{{item}}</span>
        </div>
    </div>
</template>

<script>
    
    export default {
        props:{
            lineShowList:{
                type:Array,
                default:()=>(["曝光量","点击量","收益金额"]),
            },
            mackColor:{
                type:String,
                default:'#28A5DC'
            },
            checkedValue:{
                type:String,
                default:'曝光量'
            },
            selectId:{
                type:String,
                required:true
            },
            optionId:{
                type:String,
                required:true
            },
            nowposition:{
                type:String,
                default:'left'
            }
        },
        data:()=>({
            isOpen:false,
            newValue:'',
        }),
        computed:{
            showValue(){
                return this.newValue || this.checkedValue
            }
        },
        methods: {
            handleHover() {
                this.$refs.diyRef.style.background = "#F5F7F9"
            },
            handleNone() {
                this.$refs.diyRef.style.background = "#ffffff"
            },
            handleCheckOption(str) {
                this.isOpen = false
                this.newValue = str
                this.$emit('passtitle',{position:this.nowposition,title:str})
            }
        },
        mounted() {
            document.addEventListener('click',(e)=>{
                let userCon = document.getElementById(this.selectId)
                let outBox = document.getElementById(this.optionId);
                if(userCon !==  null){
                    if(!outBox.contains(e.target) && !userCon.contains(e.target)) {
                        this.isOpen = false
                    }
                }
            })
        },
}
</script>

<style lang="less" scoped>
    .diySelect{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 180px;
        height: 36px;
        border-radius: 1px;
        border: 1px solid #D5D5D6;
        box-sizing: border-box;
        padding:0 12px;
        margin-right:16px;
        cursor: pointer;
        i{
            color:#a6a9b3;
            font-size: 12px;
        }
        .showDataItem{
            margin-left: -56px;
            font-size: 14px;
        }
        .colorBlock{
            width:11px;
            height: 4px;
        }
        .diyOptions{
            width: 178px;
            position: absolute;
            top: 34px;
            left:-1px;
            display: flex;
            flex-direction: column;
            background: white;
            border-radius: 1px;
            border: 1px solid #D5D5D6;
            z-index: 100;
            span{
                width:100%;
                line-height:36px;
                padding:0 12px;
                box-sizing: border-box;
                font-size: 14px;
                &:hover{
                    background:#F5F7F9;
                }
                &.active{
                    background:#f0f6ff;
                }
            }
        }
    }
</style>