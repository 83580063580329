<template>
    <div class="parent">
        <div class="coverFront"></div>
        <div class="lineBox">
            <div class="funnelBar funnel1">
               <p>广告位请求</p> 
               <span>{{resData.ad_request}}</span>
            </div>
            <div class="formationAngle"></div>
            <div class="lineDash"></div>
            <div class="showRate">
                <p>广告位填充率</p>
                <p>
                    <span><b>{{resData.filling_rate}}</b>% </span>
                    <ShowRate :rateData='resData.filling_p'/>
                </p>
            </div>
        </div>
        <div class="lineBox">
            <div class="funnelBar  funnel2">
                <p>广告位返回</p>
                <span>{{resData.ad_back}}</span>
            </div>
            <div class="formationAngle"></div>
            <div class="lineDash"></div>
            <div class="showRate">
                <p>广告位曝光率</p>
                <p>
                    <span><b>{{resData.show_rate}}</b>% </span>
                    <ShowRate :rateData='resData.show_p'/>
                </p>
            </div>
        </div>
        <div class="lineBox">
            <div class="funnelBar  funnel3">
               <p>广告位曝光</p> 
               <span>{{resData.ad_show}}</span>
            </div>
            <div class="formationAngle"></div>
            <div class="lineDash"></div>
            <div class="showRate">
                <p>点击率</p>
                <p>
                    <span><b>{{resData.click_rate}}</b>% </span>
                    <ShowRate :rateData='resData.click_p'/>
                </p>
            </div>
        </div>
        <div class="lineBox">
            <div class="funnelBar funnel4">
               <p>广告位点击</p> 
               <span>{{resData.ad_click}}</span>
            </div>
            <div class="formationAngle"></div>
        </div>
    </div>
</template>

<script>
    import ShowRate from './ShowRate.vue'
    export default {
        components:{
            ShowRate
        },
        props:{
            resData:{
                type:Object,
                required:true
            }
        },

    }
</script>

<style lang="less" scoped>
    @color-primary:#28A5DC;
    @funnelColor:#b5e3f7;
    @funnelBarWidth:300px;
    @space:1px;
    .parent{
        position: relative;
        overflow: hidden;
        .coverFront{
            position: absolute;
            width: 100px;
            height: 110%;
            top: -10px;
            left:-100px;
            background: #a2cca2;
            transform: rotate(-20deg);
            transform-origin:top right;
            background: white;
        }
    }
    .lineBox{
        width:600px;
        height: 68px;
        display: flex;
        padding: @space 0;
        background: white;
        .funnelBar{
            height: 68px;
            width:@funnelBarWidth;
            background:@color-primary;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-sizing: border-box;
            padding-left:@funnelBarWidth*0.46;
            text-align: center;
            transition: all .2s ease-in-out;
            p{
                margin-bottom: 3px;
                width:90px;
                font-size:14px;
                color:#4F4146;
            }
            span{
                width:90px;
                font-size:16px;
                font-weight: 900;
            }
            &.funnel1{
                width:@funnelBarWidth*1.2;
                background: #f2f8fd;
            }
            &.funnel2{
                width:@funnelBarWidth*1.115;
                background: #eef5ff;
            }
            &.funnel3{
                width:@funnelBarWidth*1.03;
                background: #e1f3fe;
            }
            &.funnel4{
                width:@funnelBarWidth*0.945;
                background: #d1F1FF;
            }
            &:hover{
                background: @funnelColor;
            }
        }
        .formationAngle{
            width:50px;
            height: 80px;
            background: white;
            transform: rotate(20deg);
            transform-origin:top left;
        }
        .lineDash{
            flex:1;
            border-bottom: 1px dashed #dde;
            position: relative;
            left: -70px;
            top:3px;
        }
        .showRate{
            position: relative;
            top:47px;
            left:-65px;
            width: 132px;
            p{
                margin-bottom:6px;
                display: flex;
                justify-content: flex-start;
                font-size: 14px;
                &:nth-child(1){
                    color: #8F9196;
                }
                span{
                    margin-right: 8px;
                }
                b{
                    font-weight: 900;
                    font-size: 16px;
                }
                i{
                    box-sizing: border-box;
                    line-height: 16px;
                }
            }
        }
    }
    
</style>