<template>
     <i :class="['showRateEle',{fail:rateData<0,win:rateData>0}]">
        环比 
        <u v-if='rateData!==0'>{{rateData>0?'+':''}}{{rateData}}%</u>
        <u v-else>-</u>
     </i>
</template>
<script>
    export default {
        props:{
            rateData:{
                type:Number||String,
                default:0
            }
        }
    }
</script>
<style lang="less" scoped>
    .showRateEle{
        height: 22px;
        border-radius: 3px;
        font-size: 12px;
        padding: 2px;
        line-height: 22px;
        u{
            margin-left: 2px;
        }
        &.fail{
            background: #EBF9FF;
            border: 1px solid #D7F3FF;
            color: #28A5DC;
        }
        &.win{
            background:#fEeeF1;
            color:#f64b4b;
            border: 1px solid #ffc0c0;
        }
    }
</style>