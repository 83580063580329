import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'animate.css'
import './assets/css/reset.css'
import './assets/theme/index.css'
Vue.use(ElementUI)
Vue.config.productionTip = false

let userInfo = sessionStorage.getItem('userInfo')//刷新时获取用户信息到状态机
userInfo && store.commit('user/saveUserInfoMut',JSON.parse(userInfo))

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
