import {reqMsg} from '../api/staticPages'
import { Loading } from 'element-ui'
export default {
    namespaced:true,
    state:()=>({
        dataList:{
            lists:[],
            total:10
        }
    }),
    getters:{
        unReadCount(state){
            let arr = []
            if(state.dataList.lists){
                arr = state.dataList.lists.filter(item=>item.isRead==='0')
            }
            return arr.length
        }
    },
    mutations:{
        msgsMut(state,arg){
            state.dataList = arg ? arg : ({
                lists:null,
                total:10
            })
        }
    },
    actions:{
        reqMsgAct(context,arg){
            if(arg.myproof){
                var loadingInstance = Loading.service({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(165, 165, 220, .1)'
                })
            }
            reqMsg(arg.reqArg).then(res=>{
                // console.log(res)
                context.commit('msgsMut',res.data.data)
                if(arg.myproof){loadingInstance.close()}
            })
        }
    }
}