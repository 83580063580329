<template>
  <div class="loginParent">
        <img src="../assets/imgs/logo-all.png" alt="">
       <el-card class="box-card">
          <div slot="header" class="clearfix">
              <span>账号密码登录</span>
          </div>
          <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
              <el-form-item label="" prop="username">
                  <el-input v-model="ruleForm.username" placeholder="请输入您的账号"></el-input>
              </el-form-item>
              <el-form-item label="" prop="password">
                  <el-input type="password" v-model="ruleForm.password" autocomplete="off"  placeholder="请输入密码"></el-input>
              </el-form-item>
              <el-form-item>
                  <el-button class="blockBtn" type="primary" @click="submitForm('ruleForm')">授权并登录</el-button>
              </el-form-item>
          </el-form>
      </el-card>
  </div>
   
</template>
<script>
  export default {
    data() {
      var checkUsername = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请输入用户名！'));
        }
        callback();
      };
      var validatePass = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请输入密码'));
        } 
        callback();
      };
      return {
        ruleForm: {
          password: '',
          username: ''
        },
        rules: {
          password: [
            { validator: validatePass, trigger: 'blur' }
          ],
          username: [
            { validator: checkUsername, trigger: 'blur' }
          ]
        }
      };
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
              this.$store.dispatch('user/userLogin',this.ruleForm)
          } else {
            this.$message({
              showClose: true,
              message: '用户名或密码不能为空！',
              type: 'error'
            })
          }
        });
      },
    }
  }
</script>

<style lang="less" scoped>
    .loginParent{
      width: 100%;
      height: 100%;
      display: flex;
      background: #F5F9FF;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      >img{
        width:280px;
        margin-top: 3%;
        margin-bottom: 1%;
      }
      .text {
        font-size: 14px;
      }
      .item {
          margin-bottom: 18px;
      }
      .clearfix:before,.clearfix:after {
          display: table;
          content: "";
      }
      .clearfix:after {
          clear: both
      }
      .clearfix{
        height: 28px;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        text-align:center;
      }
      .box-card {
          width: 520px;
          height: 300px;
          box-shadow: 0px 0px 14px 0px #DBF4FF; 
          .el-button--primary{
              display: block !important;
              width: 100%;
              height: 100%;
              font-size: 16px;
          }
      }
      .el-card__body{
        padding: 30px !important;
      }
      .el-form{
        position:relative;
        width: 460px;
        top:10px;
        left:-36px;
      }
    }
   
</style>