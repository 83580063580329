import { Loading } from 'element-ui'
import {reportLine} from '../utils/options'
import { reportDataOptions,reportData,reportEchartData } from '../api/staticPages'
export default {
    namespaced:true,
    state:()=>({
        reportOptions:null,
        reportData:null,
        reportEchart:null,
        leftLine:'曝光量',
        rightLine:'预估收入'
    }),
    getters:{
        newLineOption(state){ 
            if(state.reportEchart){
                let {echarts} = state.reportEchart
                let {leftLine,rightLine} = state
                // console.log(leftLine,rightLine)
                let {date,show,money,click,click_rate} = echarts
                reportLine.xAxis[0].data = date
                let leftData=[],rightData=[]
                switch(leftLine){
                    case '曝光量':
                        leftData = show
                        reportLine.yAxis[0].axisLabel.formatter = '{value}'
                        break
                    case '点击量':
                        leftData = click
                        reportLine.yAxis[0].axisLabel.formatter = '{value}'
                        break
                    case '点击率':
                        leftData = click_rate
                        reportLine.yAxis[0].axisLabel.formatter = '{value}%'
                        break
                    default:
                        leftData = money
                        reportLine.yAxis[0].axisLabel.formatter = '{value} us$'

                }
                switch(rightLine){
                    case '曝光量':
                        rightData = show
                        reportLine.yAxis[1].axisLabel.formatter = '{value}'
                        break
                    case '点击量':
                        rightData = click
                        reportLine.yAxis[1].axisLabel.formatter = '{value}'
                        break
                    case '点击率':
                        rightData = click_rate
                        reportLine.yAxis[1].axisLabel.formatter = '{value}%'
                        break
                    default:
                        rightData = money
                        reportLine.yAxis[1].axisLabel.formatter = '{value} us$'

                }
                reportLine.series[0].name = leftLine
                reportLine.series[0].data = leftData
                reportLine.series[1].name = rightLine
                reportLine.series[1].data = rightData
            }
            return reportLine
       }
    },
    mutations:{
        reportOptionsMut(state,arg){
            state.reportOptions = arg
        },
        reportDataMut(state,arg){
            state.reportData = arg
        },
        reportEchartMut(state,arg){
            state.reportEchart = arg
        },
        changeLineMut(state,arg){
            if(arg.position === 'left'){
                state.leftLine = arg.title
            }else{
                state.rightLine = arg.title
            }
        }
    },
    actions:{
        reportOptionsAct(context){
            reportDataOptions().then(res=>{
                context.commit('reportOptionsMut',res.data.data)
            })
        },
        reportDataAct(context,arg){
            if(arg){
                let loadingInstance = Loading.service({
                    lock: true,
                    text: '',
                    spinner: 'el-icon-loading',
                    background: 'rgba(165, 165, 220, .1)'
                })
                reportData(arg).then(res=>{
                    context.commit('reportDataMut',res.data.data)
                    loadingInstance.close()
                })
                let {page,pageSize} = arg
                let dataReportPage = {page,pageSize}
                // console.log(dataReportPage,page,pageSize)
                sessionStorage.setItem('dataReportPageRecord',JSON.stringify(dataReportPage))
            }else{
                reportData().then(res=>{
                    context.commit('reportDataMut',res.data.data)
                })
            }
        },
        reportEchartAct(context,arg){
            if(arg){
                reportEchartData(arg).then(res=>{
                    context.commit('reportEchartMut',res.data.data)
                })
            }else{
                reportEchartData().then(res=>{
                    context.commit('reportEchartMut',res.data.data)
                })
            }
        }
    }
}