<template>
    <div ref='chart' class="chartBox"></div>
</template>

<script>
    //按需引入echart图表配置
    import * as echarts from 'echarts/core';
    import { BarChart,LineChart,FunnelChart } from 'echarts/charts';
    import {
        TitleComponent,
        TooltipComponent,
        GridComponent,
        DatasetComponent,
        ToolboxComponent,
        TransformComponent,
        LegendComponent
    } from 'echarts/components';
    import { LabelLayout, UniversalTransition } from 'echarts/features';
    import { CanvasRenderer } from 'echarts/renderers';
    //挂载echarts组件
    echarts.use([
        TitleComponent,
        TooltipComponent,
        GridComponent,
        DatasetComponent,
        TransformComponent,
        BarChart,
        LineChart,
        ToolboxComponent,
        LabelLayout,
        UniversalTransition,
        CanvasRenderer,
        LegendComponent,
        FunnelChart
    ]);
    export default {
        props:{
            option:{
                type:Object,
                require:true
            }
        },
        data:()=>({
            myChart:null,
        }),
        mounted(){
            let {chart} = this.$refs
            this.$nextTick(()=>{
                this.myChart = echarts.init(chart)
                this.myChart.setOption(this.option,true)
            })
        }
    }
</script>

<style lang="less" scoped>
    .chartBox{
        box-sizing: border-box;
        margin:20px;
        width: 100%;
        height:100%;
    }
</style>